import $http from '../helpers/api';

const AccountsModule = {
  namespaced: true,

  state: () => ({
    total: 0,
    scopes: [],
    pristine: true,
    selected: false,
  }),

  mutations: {
    setTotal: (state, total) => { state.total = total; },
    muddlePristine: (state) => { state.pristine = false; },
    setScopes: (state, scopes = []) => {
      if (!scopes.length) scopes.pristine = true;
      state.scopes = scopes;
    },
    setDefaultScope: (state, scopeId) => { state.selected = scopeId; },
  },

  getters: {
    multiselectScopes: (state) => state.scopes.map(
      (scope) => ({ scopeId: scope.scopeId, name: scope.name }),
    ),
    defaultScope: (state, getters) => {
      if (!state.selected && getters.multiselectScopes.length) {
        return getters.multiselectScopes[0].scopeId;
      }
      return state.selected;
    },
  },

  actions: {
    /**
     * Lists the scopes related to this account.
     * If accountType is SUPER lists all scopes instead.
     * Paginated in any case.
     * @param {object} params Pagination and filteringparameters.
     */
    listScopes: ({ commit }, params) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.get('/admin/scopes', { params })
          .then((res) => {
            commit('muddlePristine');
            commit('setTotal', res.data.total);
            commit('setScopes', res.data.data);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * A SUPER Account can create new Scopes.
     * @param {object} scopeData Data used to create the scope.
     */
    createScope: ({ state, commit }, scopeData) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.post('/admin/scopes', scopeData)
          .then((res) => {
            commit('setScopes', [...state.scopes, res.data]);
            commit('setTotal', state.total + 1);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * A SUPER Account can fetch an existing Scope
     * @param {string} scopeId ID of the scope to update
     */
    fetchScope: ({ commit }, scopeId) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.get(`/admin/scopes/${scopeId}`)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * A SUPER Account can update an existing Scope
     * @param {object} scopeData scopeId and data of the Scope to update
     */
    updateScope: ({ commit }, scopeData) => {
      commit('toggleLoading', null, { root: true });
      const { scopeId, ...payload } = scopeData;
      return new Promise((resolve, reject) => {
        $http.put(`/admin/scopes/${scopeId}`, payload)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    deleteScope: ({ commit }, scopeId) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.delete(`/admin/scopes/${scopeId}`)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },
  },
};

export default AccountsModule;
