/* eslint-disable no-param-reassign */
const NavbarModule = {
  namespaced: true,

  state: () => ({
    active: true,
  }),

  mutations: {
    toggleActive: (state) => { state.active = !state.active; },
    setActive: (state, active) => { state.active = active; },
  },
};

export default NavbarModule;
