const getDefaultState = () => ({
  step: 1,
  room: false,
  roomId: null,
  enlistmentToken: null,
  presenters: [],
  defRoomSettings: [],
  defAttendeePerm: [],
  defPresenterPerm: [
    ['CAN_SET_NICKNAME'],
    ['CAN_SEND_AUDIO'],
    ['CAN_SEND_VIDEO'],
    ['CAN_SHARE_SCREEN'],
  ],
  attendeePerms: [],
  presenterPerms: [],
  newPresenterModalActive: false,
});

const RoomWizardModule = {
  namespaced: true,

  state: () => getDefaultState(),

  getters: {
    roomSettingsContain: (state) => (setting) => state.defRoomSettings.include(setting),
  },

  mutations: {
    resetState: (state) => { Object.assign(state, getDefaultState()); },
    nextStep: (state) => { state.step += 1; },
    prevStep: (state) => { state.step -= 1; },
    setRoom: (state, room) => { state.room = room; },
    setRoomId: (state, roomId) => { state.roomId = roomId; },
    setEnlistmentToken: (state, enlistmentToken) => { state.enlistmentToken = enlistmentToken; },
    setPresenters: (state, presenters) => { state.presenters = presenters; },
    pushPresenter: (state, presenter) => { state.presenters.push(presenter); },
    splicePresenter: (state, index) => { state.presenters.splice(index, 1); },
    togglePresenterModal: (state) => {
      state.newPresenterModalActive = !state.newPresenterModalActive;
    },
    setAttendeePerms: (state, perms) => { state.attendeePerms = perms; },
    setPresenterPerms: (state, perms) => { state.presenterPerms = perms; },
  },

  actions: {
    updateParticipations: ({ state, commit, dispatch }) => new Promise((resolve, reject) => {
      dispatch('participations/fetchParticipations', { params: { roomId: state.roomId, role: 'PRESENTER' } }, { root: true })
        .then((res) => resolve(commit('setPresenters', res.data)))
        .catch((err) => reject(err));
    }),
  },
};

export default RoomWizardModule;
