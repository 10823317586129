import $http from '../helpers/api';

const AccountsModule = {
  namespaced: true,

  state: () => ({
    pristine: true,
    accounts: [],
    total: 0,
  }),

  mutations: {
    setTotal: (state, total) => { state.total = total; },
    muddlePristine: (state) => { state.pristine = false; },
    setAccounts: (state, accounts) => { state.accounts = accounts; },
  },

  actions: {
    /**
     * Lists the Accounts related to a certain Scope.
     * @param {object} params Pagination and filtering parameters
     */
    listAccounts: ({ commit }, params) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.get('/admin/accounts', { params })
          .then((res) => {
            commit('muddlePristine');
            commit('setTotal', res.data.total);
            commit('setAccounts', res.data.data);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * A SUPER Account can create new ADMIN accounts in a given Scope.
     * @param {object} accountData data from which to create the new Account
     */
    createAccount: ({ commit, state }, accountData) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.post('/admin/accounts', accountData)
          .then((res) => {
            commit('setAccounts', [...state.accounts, res.data]);
            commit('setTotal', state.total + 1);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * A SUPER Account can fetch any Account regardless on Scope.
     * An ADMIN Account can't fetch other accounts.
     * @param {string} accountId ID of the Account to fetch
     */
    fetchAccount: ({ commit }, accountId) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.get(`/admin/accounts/${accountId}`)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * A SUPER Account can update any Account regardless on Scope.
     * An ADMIN Account can't update other accounts.
     * @param {*} accountData data used to update the Account
     */
    updateAccount: ({ commit }, accountData) => {
      commit('toggleLoading', null, { root: true });
      const { accountId, ...payload } = accountData;
      return new Promise((resolve, reject) => {
        $http.put(`/admin/accounts/${accountId}`, payload)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * A SUPER Account can delete any Account regardless on Scope.
     * An ADMIN Account can't delete other accounts.
     * @param {string} accountId ID of the Account to delete
     */
    deleteAccount: ({ commit }, accountId) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.delete(`/admin/accounts/${accountId}`)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
          .then(() => commit('toggleLoading', null, { root: true }));
      });
    },
  },
};

export default AccountsModule;
