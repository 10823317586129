import store from '../store';

const { localStorage } = window;

export const AUTH_TYPES = {
  LOCAL: 'local',
  OIDC: 'oidc',
};

export const userSessionExists = () => !!localStorage.getItem('user_session') || false;

export const removeSession = () => localStorage.removeItem('user_session');

export const setSession = (session, expires) => {
  localStorage.setItem('user_session', session, { expires });
};

export const checkAuth = async (to, from, next) => {
  // public pages are not accessible if user is logged in
  if (!to.meta.auth) {
    if (!store.getters['auth/getAuth']) return next();
    return next({ name: from.name });
  }

  // Page is prviate, but user is logged in
  if (store.getters['auth/getAuth']) return next();

  // Rehidrate the JWT if it exists
  if (!store.getters['auth/getAuth'] && userSessionExists()) {
    store.commit('auth/setBearer', localStorage.getItem('user_session'));
  }

  // A call to this endpoint should return a User and continue.
  // Otherwise, redirect to login.
  return (await store.dispatch('auth/getAccountType')) ? next() : next({ name: 'Login' });
};

export const redirectTo = () => {
  if (store.state.auth.isAuth) {
    if (store.state.auth.accountType === 'SUPER') return 'ScopeIndex';
    return 'RoomIndex';
  }

  return 'Login';
};
