const presenterRoutes = [
  {
    path: '/hosts',
    name: 'PresenterIndex',
    component: () => import(/* webpackChunkName: 'presenters' */ '../views/presenters/Index'),
    meta: {
      auth: true,
      title: 'Presenters',
      loadingDialog: false,
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/hosts/create',
    name: 'PresenterCreate',
    component: () => import(/* webpackChunkName: 'presenters' */ '../views/presenters/Create'),
    meta: {
      auth: true,
      title: 'Add a Host',
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/hosts/:presenterId',
    name: 'PresenterUpdate',
    props: true,
    component: () => import(/* webpackChunkName: 'presenters' */ '../views/presenters/Update'),
    meta: {
      auth: true,
      title: 'Update a Host',
      layout: 'DashboardLayout',
    },
  },
];

export default presenterRoutes;
