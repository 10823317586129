import axios from 'axios';

const OIDC_ENABLED = process.env.VUE_APP_OIDC_LOGIN_ENABLED === 'true';

let axiosConfig = {
  baseURL: process.env.VUE_APP_BASEAPI_URL,
};

if (OIDC_ENABLED) {
  axiosConfig = {
    ...axiosConfig,
    withCredentials: true,
    credentials: 'include',
  };
}

const $http = axios.create(axiosConfig);

export default $http;
