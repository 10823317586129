import { findIndex } from 'lodash-es';
import $http from '../helpers/api';

const UserModule = {
  namespaced: true,

  state: () => ({
    users: [],
    total: 0,
    selected: {},
    pristine: true,
  }),

  getters: {
    getUserById: (state) => (userId) => {
      state.users.find((user) => user.id === userId);
    },
  },

  mutations: {
    setTotal: (state, total) => { state.total = total; },
    muddlePristine: (state) => { state.pristine = false; },
    setUsers: (state, users) => { state.users = users; },
    appendUser: (state, user) => state.users.push(user),
    removeUser: (state, userId) => {
      const index = findIndex(state.users, ['userId', userId]);
      if (index > -1) state.users.splice(index, 1);
    },
    spliceUser: (state, userData) => {
      const index = findIndex(state.users, ['id', userData.id]);
      if (index > -1) state.users.splice(index, 1, userData);
    },
  },

  actions: {
    /**
     * [GET] /admin/users
     *
     * @params { roomId: string!, role: enum[PRESENTER|ATTENDEE]! }
     * @returns Participant[]
     *
     * Gets a listing of participans, it can be filtered using the
     * available query parameters.
     */
    fetchUsers: ({ commit }, params) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.get('admin/users', { params })
          .then((res) => {
            commit('muddlePristine');
            commit('setUsers', res.data.data);
            commit('setTotal', res.data.total);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * [GET] /admin/users/:userId
     *
     * @params userId string
     * @returns Participant
     *
     * Fetches a participanr
     */
    fetchUser: ({ commit, getters }, userId) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        const participant = getters.getUserById(userId);

        if (!participant) {
          $http.get(`admin/users/${userId}`)
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => reject(err))
            .finally(() => commit('toggleLoading', null, { root: true }));
        } else {
          commit('toggleLoading', null, { root: true });
          resolve(participant);
        }
      });
    },

    /**
     * [POST] /admin/users
     *
     * @data CreateParticipantRequestDto
     * @returns Participant
     *
     * Creates a new participant in the system and updates local storage.
     */
    createUser: ({ commit }, userData) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.post('admin/users', userData)
          .then((res) => resolve(res.data))
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },

    /**
     * [POST] /admin/users/:userId
     *
     * @params userId string
     * @data CreateParticipantRequestDto
     * @returns Participant
     *
     * Updates the selected participant in the system and updates local storage.
     */
    updateUser: ({ commit }, userData) => {
      const noLoading = userData.loading !== undefined || userData.loading;
      delete userData.loading;
      if (!noLoading) commit('toggleLoading', null, { root: true });

      return new Promise((resolve, reject) => {
        const userId = userData.id;
        delete userData.id;

        $http.put(`admin/users/${userId}`, userData)
          .then((res) => {
            commit('spliceUser', userData);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .finally(() => { if (!noLoading) commit('toggleLoading', null, { root: true }); });
      });
    },

    /**
     * [DELETE] /admin/users/:userId
     *
     * @params userId string
     * @returns Participant
     *
     * Deletes the selected user from storage and DB.
     */
    deleteUser: ({ commit }, userId) => {
      commit('toggleLoading', null, { root: true });
      return new Promise((resolve, reject) => {
        $http.delete(`admin/users/${userId}`)
          .then((res) => {
            commit('removeUser', userId);
            resolve(res.data);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },
  },
};

export default UserModule;
