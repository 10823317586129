const roomRoutes = [
  {
    path: '/webinar-rooms',
    name: 'RoomIndex',
    component: () => import(/* webpackChunkName: "rooms" */ '../views/rooms/Index'),
    meta: {
      auth: true,
      title: 'Webinar rooms',
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/webinar-rooms/create',
    name: 'RoomCreate',
    component: () => import(/* webpackChunkName: "rooms" */ '../views/rooms/Create'),
    meta: {
      auth: true,
      title: 'Create webinar room',
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/webinar-rooms/:roomId/update',
    name: 'RoomUpdate',
    props: true,
    component: () => import(/* webpackChunkName: "rooms" */ '../views/rooms/Update'),
    meta: {
      auth: true,
      title: 'Update webinar room',
      layout: 'DashboardLayout',
    },
  },
];

export default roomRoutes;
