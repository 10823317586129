import $http from '../helpers/api';

const ParticipationsModule = {
  namespaced: true,

  state: {
    loading: false,
  },

  mutations: {
    toggleLoading: (state) => { state.loading = !state.loading; },
  },

  actions: {
    /**
     * [GET] /admin/participations
     *
     * @queryObject {
     *  loading: boolean,
     *  ...@params
     * }
     * @params {
     *  roomId: string,
     *  userId: string,
     *  nickname: string,
     *  email: string,
     *  role: enum[PRESENTER,ATTENDEE],
     *  skip: integer,
     *  take: integer,
     *  sortBy: enum[nickname, createdAt, updatedAt],
     *  sortOrder: enum[ASC,DESC],
     *  fetchUser: boolean
     * }
     * @returns ParticipationResponseDto[]
     *
     * Returns a filtered and paginated array of participations.
     */
    fetchParticipations: ({ commit }, queryObject) => new Promise((resolve, reject) => {
      const { usesLoading, params } = queryObject;

      if (usesLoading) commit('toggleLoading');
      $http.get('/admin/participations', { params })
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
        .finally(() => {
          if (usesLoading) commit('toggleLoading');
        });
    }),

    /**
     * [GET] /admin/participations/:participationId
     *
     * @queryObject {
     *  participationId*: string,
     *  loading: boolean
     * }
     * @returns ParticipationResponseDto
     *
     * Returns a participation object by ID, including the related User.
     */
    fetchParticipation: ({ commit }, queryObject) => new Promise((resolve, reject) => {
      const usesLoading = queryObject.loading;
      delete queryObject.loading;

      if (usesLoading) commit('toggleLoading');
      $http.get(`/admin/participations/${queryObject.participationId}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
        .finally(() => {
          if (usesLoading) commit('toggleLoading');
        });
    }),

    /**
     * [POST] /admin/participations
     *
     * @data CreateParticipationRequestDto
     * @returns ParticipationResponseDto
     *
     * Creates a participation model for a given presenter to a room.
     */
    createParticipation: ({ commit }, participationData) => new Promise((resolve, reject) => {
      const usesLoading = participationData.loading;
      delete participationData.loading;

      if (usesLoading) commit('toggleLoading');
      $http.post('/admin/participations', participationData)
        .then((res) => resolve(res))
        .catch((err) => reject(err))
        .finally(() => {
          if (usesLoading) commit('toggleLoading');
        });
    }),

    /**
     * [PUT] /admin/participations/:participationId
     * TO DO
     *
     * @parameters participationId: string
     * @data UpdateParticipationRequestDto
     * @returns ParticipationResponseDto
     *
     * Updates a participation model for a given UserxRoom and permission sets.
     */
    updateParticipation: ({ commit }, queryObject) => new Promise((resolve, reject) => {
      const { usesLoading, participationId } = queryObject;
      delete queryObject.loading;
      delete queryObject.participationId;

      if (usesLoading) commit('toggleLoading');
      $http.put(`/admin/participations/${participationId}`, queryObject)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
        .finally(() => {
          if (usesLoading) commit('toggleLoading');
        });
    }),

    /**
     * [DELETE] /admin/participations/:participationId
     * TO DO
     *
     * @parameters participationId: string
     * @returns ParticipationResponseDto
     *
     * Deletes a participation model.
     */
    deleteParticipation: ({ commit }, queryObject) => new Promise((resolve, reject) => {
      const { usesLoading = false, participationId } = queryObject;

      if (usesLoading) commit('toggleLoading');
      $http.delete(`/admin/participations/${participationId}`)
        .then((res) => resolve(res.data))
        .catch((err) => reject(err))
        .finally(() => {
          if (usesLoading) commit('toggleLoading');
        });
    }),
  },
};

export default ParticipationsModule;
