const scopeRoutes = [
  {
    path: '/scopes',
    name: 'ScopeIndex',
    component: () => import(/* webpackChunkName: 'scopes' */ '../views/scopes/Index'),
    meta: {
      auth: true,
      only: 'SUPER',
      title: 'Scope listing',
      loadingDialog: false,
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/scopes/create',
    name: 'ScopeCreate',
    component: () => import(/* webpackChunkName: 'scopes' */ '../views/scopes/Create'),
    meta: {
      auth: true,
      only: 'SUPER',
      title: 'Scope creation',
      loadingDialog: false,
      layout: 'DashboardLayout',
    },
  },
  {
    path: '/scopes/:scopeId',
    name: 'ScopeUpdate',
    props: true,
    component: () => import(/* webpackChunkName: 'scopes' */ '../views/scopes/Update'),
    meta: {
      auth: true,
      only: 'SUPER',
      title: 'Update a Scope',
      loadingDialog: false,
      layout: 'DashboardLayout',
    },
  },
];

export default scopeRoutes;
