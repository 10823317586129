const AlertModule = {
  namespaced: true,

  state: () => ({
    alert: false,
    defaults: {
      type: 'error',
      timeout: 4500,
      icon: 'mdi-cloud-alert',
      transition: 'scroll-y-transition',
    },
  }),

  mutations: {
    setAlert: (state, alert = false) => {
      window.scroll(0, 0);
      state.alert = { ...state.defaults, ...alert };
    },
  },

  getters: {
    showAlert: (state) => !!state.alert && !!state.alert.message,
  },
};

export default AlertModule;
