import $http from '../helpers/api';

const AuthModule = {
  namespaced: true,

  state: () => ({
    bearer: false,
    refresh: false,
    refreshing: false,
    accessTokenExpiry: false,
    accountType: 'ADMIN',
    username: '',
    isAuth: false,
    isChecking: false,
  }),

  mutations: {
    removeAccessToken: (state) => { state.bearer = false; },
    removeTokens: (state) => {
      state.bearer = false;
      state.refresh = false;
      state.accessTokenExpiry = false;
    },
    setBearer: (state, bearer) => { state.bearer = bearer; },
    setTokens: (state, tokens) => {
      const { accessTokenExpiry, accessToken, refreshToken } = tokens;
      state.bearer = accessToken;
      state.refresh = refreshToken;
      state.accessTokenExpiry = accessTokenExpiry;
    },
    setRefreshing: (state, refreshing) => { state.refreshing = refreshing; },
    setAccountType: (state, accountType) => { state.accountType = accountType; },
    setUserName: (state, username) => { state.username = username; },
    setIsAuth: (state, isAuth) => { state.isAuth = isAuth; },
    setIsChecking: (state, isChecking) => { state.isChecking = isChecking; },
  },

  getters: {
    getBearerToken: (state) => state.bearer,
    getRefreshToken: (state) => state.refresh,
    getAuth: (state) => state.isAuth,
  },

  actions: {
    login({ state, commit }, authData) {
      return new Promise((resolve, reject) => {
        $http.post('accounts/login', authData)
          .then((res) => {
            const { accountType, username, ...tokens } = res.data;
            commit('setIsAuth', true);
            commit('setTokens', tokens);
            if (accountType !== state.accountType) commit('setAccountType', accountType);
            commit('setUserName', username);
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },

    refreshTokens({ state, commit }) {
      commit('toggleLoading', null, { root: true });

      return new Promise((resolve, reject) => {
        $http.get('accounts/refresh')
          .then((res) => {
            const { accountType, username, ...tokens } = res.data;
            commit('setTokens', tokens);
            if (accountType !== state.accountType) commit('setAccountType', accountType);
            commit('setUserName', username);
            resolve(tokens);
          })
          .catch((err) => reject(err))
          .finally(() => commit('toggleLoading', null, { root: true }));
      });
    },

    logout({ commit }) {
      commit('removeTokens');
      commit('setRefreshing', false);
      commit('setUserName', '');
      commit('setAccountType', 'ADMIN');
      commit('setRefreshing', false);
      commit('setIsAuth', false);
    },

    getAccountType: ({ commit }) => new Promise(
      (resolve) => {
        $http.get('admin/me')
          .then((res) => {
            commit('setIsAuth', true);
            commit('setAccountType', res.data.accountType);
            resolve(true);
          })
          .catch(() => resolve(false));
      },
    ),
  },
};

export default AuthModule;
